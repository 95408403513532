import * as React from "react";
import * as styles from "./shop.module.css";

import ShopContainer, { Ingredient } from "../components/Shop";
import { brandsToExclude, categoriesToExclude } from "../queries/toExclude";

import { FetchAllCategories } from "../components/hooks/fetchAllCategories";
import Layout from "../components/Base/Layout";
import { Location } from "@reach/router";
import { SEO } from "../components/Base/Seo";
import ShopProductsContainer from "../components/Shop/shop-products-container";
import { fetchAllBrands } from "../queries/fetchAllBrands";
import { fetchAllProducts } from "../components/hooks/queries";
import useAuth from "../components/useAuth";

type ShopProps = {
  location: Location;
};
const Shop = ({ location }: ShopProps) => {
  const { loggedIn, isUnlicensedUser, isDefaultCustomer } = useAuth();

  const categories = FetchAllCategories();
  const brands = fetchAllBrands();
  const allProducts = fetchAllProducts();

  const filteredBrands = React.useMemo(() => {
    if (isUnlicensedUser || !loggedIn || isDefaultCustomer) {
      return brands.filter((item: any) => {
        return !brandsToExclude.includes(item.name);
      });
    }

    return brands.filter((item: any) => {
      return (
        item.name !== "Unlicensed" &&
        item.name !== "Medical Devices" &&
        item.name !== "Pharmaceuticals"
      );
    });
  }, [isUnlicensedUser, loggedIn, isDefaultCustomer, brands]);

  const relatedProductCategoryName = "Related Product";
  const filteredCategories = React.useMemo(() => {
    if (isUnlicensedUser || !loggedIn || isDefaultCustomer) {
      return categories.filter((item: any) => {
        return (
          !categoriesToExclude.includes(item.name) &&
          item.name !== relatedProductCategoryName &&
          item.name !== "Recommended Products" &&
          item.name !== "Best Selling Product"
        );
      });
    }

    return categories?.filter(
      (item: any) =>
        item.name !== relatedProductCategoryName &&
        item.name !== "Recommended Products" &&
        item.name !== "Best Selling Product"
    );
  }, [isUnlicensedUser, isDefaultCustomer, categories, categoriesToExclude]);

  const filteredProducts = React.useMemo(() => {
    let allFilteredProducts = allProducts;

    if (isUnlicensedUser || !loggedIn || isDefaultCustomer) {
      allFilteredProducts = allProducts.filter((item: any) => {
        return item.productTags?.nodes.every(
          (tag: any) =>
            tag.name !== "Unlicensed" && tag.name !== "Pharmaceuticals"
        );
      });
    }
    return allFilteredProducts;
  }, [isUnlicensedUser, isDefaultCustomer, allProducts, loggedIn]);

  const ingredients = React.useMemo(() => {
    const uniqueIngredients: any[] = [];
    // const uniqueIngredientsById: IngredientTypeObj = {};

    filteredProducts.forEach((product: any) => {
      product?.allPaActiveIngredient?.nodes.forEach(
        (ingredient: Ingredient) => {
          if (
            ingredient.name !== "N/A"
            // !uniqueIngredientsById[ingredient.id]
          ) {
            // uniqueIngredientsById[ingredient.id] = ingredient;
            uniqueIngredients.push(ingredient);
          }
        }
      );
    });

    return uniqueIngredients;
  }, [filteredProducts]);

  return (
    <>
      <Layout>
        <div className={styles.shop}>
          <div className={styles.shoppage}>
            {/* @ts-ignore */}
            <ShopProductsContainer
              title="All Products"
              // @ts-ignore
              location={location}
              categories={filteredCategories}
              brands={filteredBrands}
              products={filteredProducts}
              pageContext={undefined}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export const Head = () => (
  <SEO
    title="Full Product List | DoctorMedica"
    description="See our entire product selection and browse for your favourite supplies quickly and easily."
  />
);

export default Shop;
